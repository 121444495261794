import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../utils/enums";
import cn from "classnames";
import "./switcher.scss";

const block = "switcher";
function Switcher({ firstOption, secondOption, changeHandler }) {
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState();

  const handleIndexChange = useCallback(
    (selectedIndex) => {
      setSelectedIndex(selectedIndex);
      changeHandler(selectedIndex);
    },
    [changeHandler]
  );

  useEffect(() => {
    setSelectedIndex(location.pathname === ROUTES.all ? 0 : 1);
  }, [location]);

  return (
    <div className={block}>
      <button
        className={cn(`${block}__button`, {
          [`${block}__button--active`]: selectedIndex === 0,
        })}
        onClick={() => {
          handleIndexChange(0);
        }}
      >
        {firstOption}
      </button>
      <button
        className={cn(`${block}__button`, {
          [`${block}__button--active`]: selectedIndex === 1,
        })}
        onClick={() => {
          handleIndexChange(1);
        }}
      >
        {secondOption}
      </button>
    </div>
  );
}

Switcher.protoTypes = {
  firstOption: PropTypes.string.isRequired,
  secondOption: PropTypes.string.isRequired,
  changeHandler: PropTypes.func,
};

export default Switcher;

import PropTypes from "prop-types";
import { useCallback, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PostsContext } from "../pages/home";
import { ROUTES } from "../utils/enums";
import "./modal.scss";

const block = "modal";
function Modal({ className, children }) {
  const { setModalContext } = useContext(PostsContext);
  const location = useLocation();
  const navigate = useNavigate();

  const handleGlobalKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        if (!location.pathname.includes(ROUTES.profile)) setModalContext(null);
        else navigate(location.state?.prevPath || "/", { replace: true });
      }
    },
    [location, setModalContext, navigate]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleGlobalKeyDown);

    return () => {
      document.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [handleGlobalKeyDown]);

  return <div className={`${block} ${className}`}>{children}</div>;
}

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
};

export default Modal;

import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PostsContext } from "../pages/home";
import CreatePost from "./create-post";
import PostList from "./post-list";
import "./profile.scss";

const block = "profile";
function Profile({ className, user }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { postsContext, usersContext, setUsersContext } =
    useContext(PostsContext);
  const following = useMemo(
    () => usersContext[0].following?.includes(user.id),
    [user, usersContext]
  );
  const followers = useMemo(
    () =>
      usersContext.filter((contextUser) =>
        contextUser.following?.includes(user.id)
      ),
    [usersContext, user]
  );
  const postsByUser = useMemo(
    () => postsContext.filter((post) => post.userId === user.id),
    [postsContext, user]
  );

  const toggleFollow = useCallback(() => {
    const [defaultUser, ...otherUsers] = usersContext;

    if (following)
      defaultUser.following = defaultUser.following.filter(
        (followedUser) => followedUser !== user.id
      );
    else defaultUser.following = [...defaultUser.following, user.id];

    setUsersContext([defaultUser, ...otherUsers]);
  }, [setUsersContext, following, usersContext, user]);

  const dismiss = useCallback(() => {
    navigate(location.state?.prevPath || "/", { replace: true });
  }, [location, navigate]);

  return (
    <div className={`${block} ${className}`}>
      <button className={`${block}__close-button`} onClick={dismiss}>
        [ esc ]
      </button>
      <div className={`${block}__inner`}>
        <header className={`${block}__header`}>
          <div
            className={`${block}__avatar`}
            style={{ backgroundColor: user.avatarColor }}
          >
            {user?.handle?.charAt(0)}
          </div>
          <div className={`${block}__handle`}>{user.handle}</div>
          <div className={`${block}__info`}>
            Following&nbsp;{user.following?.length}&nbsp;•&nbsp;Followers&nbsp;
            {followers?.length}&nbsp;•&nbsp;Posts&nbsp;{postsByUser.length}
            <br />
            Joined at {user.joinedAt}
          </div>

          {user.id !== 1 && (
            <button
              className={`${block}__follow-button`}
              onClick={toggleFollow}
            >
              &gt;&nbsp;{following ? "unfollow" : "follow"}
            </button>
          )}
        </header>
        <CreatePost user={user} />
        <div className={`${block}__label`}>Posts by {user.handle}</div>
        <PostList readOnly externalData={postsByUser} />
      </div>
    </div>
  );
}

Profile.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
};

export default Profile;

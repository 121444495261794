import PropTypes from "prop-types";
import { useMemo } from "react";
import { USERS } from "../utils/enums";
import "./header.scss";
import User from "./user";

const block = "header";
function Header({ className }) {
  const user = useMemo(() => {
    return USERS[0];
  }, []);

  return (
    <header className={`${block} ${className}`}>
      <div className={`${block}__container container`}>
        <div className={`${block}__logo`}>Posterr</div>
        <User user={user} avatarOnly />
      </div>
    </header>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;

import { today } from "../utils/date";
import { API } from "../utils/enums";

/**
 * Creates a new post
 * @param  {Array} contextData Array in which the new post will be added
 * @param  {function} contextSetter Function to update the contextData
 * @param  {string} userId User that owns the new post
 * @param  {string} message Post message
 * @returns {Promise} Promise object with a boolean
 */
const createPost = (contextData, contextSetter, userId, message) => {
  const post = {
    message,
  };

  return persistPost(post, contextData, contextSetter, userId);
};

/**
 * Reposts a post
 * @param  {Array} contextData Array in which the new post will be added
 * @param  {function} contextSetter Function to update the contextData
 * @param  {string} userId User that owns the new post
 * @param  {Object} originalPost Reposted post
 * @returns {Promise} Promise object with a boolean
 */
const createRepost = (contextData, contextSetter, userId, originalPost) => {
  const post = {
    originalPost: originalPost,
  };

  return persistPost(post, contextData, contextSetter, userId);
};

/**
 * Quotes a post
 * @param  {Array} contextData Array in which the new post will be added
 * @param  {function} contextSetter Function to update the contextData
 * @param  {string} userId User that owns the new post
 * @param  {string} message Post message
 * @param  {Object} quotedPost Quoted post
 * @returns {Promise} Promise object with a boolean
 */
const createQuote = (
  contextData,
  contextSetter,
  userId,
  message,
  quotedPost
) => {
  const post = {
    message,
    quotedPost: quotedPost,
  };

  return persistPost(post, contextData, contextSetter, userId);
};

/**
 * Persists a post
 * @param  {Object} post Post to persist
 * @param  {Array} contextData Array in which the new post will be added
 * @param  {function} contextSetter Function to update the contextData
 * @param  {string} userId User that owns the new post
 * @returns {Promise} Promise object with a boolean
 */
const persistPost = (post, contextData, contextSetter, userId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      contextSetter([
        {
          ...post,
          id: contextData?.length + 1,
          createdAt: today(),
          userId: userId,
        },
        ...contextData,
      ]);

      resolve(true);
    }, API.fakeDelayInSecs);
  });
};

export { createPost, createRepost, createQuote };

import PropTypes from "prop-types";
import { useMemo } from "react";
import { USERS } from "../utils/enums";
import User from "./user";
import "./post.scss";

const block = "post";
function Post({
  className,
  userId,
  createdAt,
  message,
  originalPost,
  quotedPost,
  hideActions,
  repostHandler,
  quoteHandler,
  readOnly,
}) {
  const user = useMemo(
    () => USERS.filter((user) => user.id === userId).at(0),
    [userId]
  );

  const messageChunk = useMemo(() => {
    if (originalPost) {
      return <Post {...originalPost} readOnly />;
    } else if (quotedPost)
      return (
        <>
          <div className={`${block}__main`}>
            <div className={`${block}__message`}>{message}</div>
          </div>
          <Post {...quotedPost} readOnly />
        </>
      );
    return (
      <div className={`${block}__main`}>
        <div className={`${block}__message`}>{message}</div>
      </div>
    );
  }, [originalPost, message, quotedPost]);

  const footerChunk = useMemo(() => {
    if (!hideActions && !readOnly)
      return (
        <footer className={`${block}__footer`}>
          <button onClick={() => repostHandler && repostHandler()}>
            &gt;&nbsp;repost
          </button>
          <button onClick={() => quoteHandler && quoteHandler()}>
            &gt;&nbsp;quote
          </button>
        </footer>
      );

    return null;
  }, [hideActions, repostHandler, quoteHandler, readOnly]);

  const actionBadgeChunk = useMemo(() => {
    let actionLabel;

    if (originalPost) actionLabel = "reposted";
    else if (quotedPost) actionLabel = "quoted";
    else actionLabel = "posted";

    return (
      <>
        <div className={`${block}__post-type-pill-separator`}>•</div>
        <div className={`${block}__post-type-pill`}>{actionLabel}</div>
      </>
    );
  }, [originalPost, quotedPost]);

  return (
    <div className={`${block} ${className}`}>
      <header className={`${block}__header`}>
        {user && (
          <User user={user} date={createdAt} actionBadge={actionBadgeChunk} />
        )}
      </header>
      <div className={`${block}__main`}>{messageChunk}</div>
      {footerChunk}
    </div>
  );
}

Post.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  message: PropTypes.string,
  originalPost: PropTypes.object,
  quotedPost: PropTypes.object,
  hideActions: PropTypes.bool,
  repostHandler: PropTypes.func,
  quoteHandler: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default Post;

import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { PostsContext } from "../pages/home";
import { createRepost } from "../services/post-service";
import CreatePost from "./create-post";
import Post from "./post";
import { POSTS, ROUTES } from "../utils/enums";
import "./post-list.scss";
import Loading from "./loading";
import Alert from "./alert";

const block = "post-list";
function PostList({ readOnly, externalData }) {
  const location = useLocation();
  const { postsContext, setPostsContext, setModalContext, usersContext } =
    useContext(PostsContext);
  const [listItems, setListItems] = useState();
  const myPosts = useMemo(
    () => postsContext.filter((post) => post.userId === 1),
    [postsContext]
  );

  const handleRepost = useCallback(
    async (post) => {
      if (myPosts?.length === POSTS.maxPostsPerDay) {
        setModalContext(
          <Alert
            title="Oops"
            message="You've reached the maximum number of daily posts."
          />
        );
        return;
      }

      let repostedPost = post;
      const { quotedPost, originalPost, ...nakedPost } = post;

      if (quotedPost) repostedPost = nakedPost;
      else if (originalPost) repostedPost = originalPost;

      setModalContext(<Loading />);
      await createRepost(postsContext, setPostsContext, 1, repostedPost);
      setModalContext(null);
    },
    [postsContext, setPostsContext, setModalContext, myPosts]
  );

  const handleQuote = useCallback(
    (post) => {
      setModalContext(
        <CreatePost className={`${block}__create-post`} quote={post} />
      );
    },
    [setModalContext]
  );

  useEffect(() => {
    if (externalData) setListItems(externalData);
    else {
      if (location.pathname === ROUTES.all) {
        setListItems(postsContext);
      } else if (!location.pathname.includes(ROUTES.profile)) {
        const followingPosts = postsContext.filter(
          (post) =>
            usersContext[0].following.find((userId) =>
              [post.userId].includes(userId)
            ) !== undefined
        );

        setListItems(followingPosts);
      }
    }
  }, [postsContext, location, externalData, usersContext]);

  return (
    <div className={block}>
      {listItems?.map((post) => (
        <Post
          className={`${block}__post`}
          key={post.id}
          userId={post.userId}
          createdAt={post.createdAt}
          message={post.message}
          originalPost={post.originalPost}
          quotedPost={post.quotedPost}
          repostHandler={() => handleRepost(post)}
          quoteHandler={() => handleQuote(post)}
          readOnly={readOnly}
        />
      ))}
      {!listItems?.length && (
        <div className={`${block}__empty`}>
          No posts from your followers (Reset data to follow Third User back).
        </div>
      )}
    </div>
  );
}

PostList.prototypes = {
  readOnly: PropTypes.bool,
  externalData: PropTypes.array,
};

export default PostList;

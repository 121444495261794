import { useCallback, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { PostsContext } from "../pages/home";
import { POSTS, ROUTES } from "../utils/enums";
import { createPost, createQuote } from "../services/post-service";
import "./create-post.scss";
import Loading from "./loading";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "./alert";

const block = "create-post";
function CreatePost({ className, quote, user }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { postsContext, setModalContext, setPostsContext } =
    useContext(PostsContext);
  const [postMessage, setPostMessage] = useState("");
  const charCountBarWidth = useMemo(
    () => `${(100 * postMessage.length) / POSTS.maxChars}%`,
    [postMessage]
  );
  const numOfRemainChars = useMemo(
    () => POSTS.maxChars - (postMessage?.length || 0),
    [postMessage]
  );
  const maxCharsHighlight = useMemo(
    () => postMessage.length >= POSTS.maxChars - 3,
    [postMessage]
  );
  const userPosts = useMemo(
    () => postsContext.filter((post) => post.userId === (user?.id || 1)),
    [postsContext, user]
  );

  const handleCreatePost = useCallback(async () => {
    const ownerId = user?.id || 1;

    if (userPosts?.length === POSTS.maxPostsPerDay) {
      setModalContext(
        <Alert
          title="Oops"
          message={`${
            user ? user.handle + ` has ` : `You've `
          }reached the maximum number of daily posts.`}
        />
      );
      return;
    }

    setModalContext(<Loading />);

    if (quote) {
      const { originalPost, quotedPost, ...nakedQuotedPost } = quote;

      await createQuote(
        postsContext,
        setPostsContext,
        ownerId,
        postMessage,
        originalPost || nakedQuotedPost
      );
    } else
      await createPost(postsContext, setPostsContext, ownerId, postMessage);

    setModalContext(null);
    setPostMessage("");
    navigate("/", { replace: true });
  }, [
    postsContext,
    setPostsContext,
    postMessage,
    quote,
    setModalContext,
    user,
    navigate,
    userPosts,
  ]);

  const handlePostMessageChange = useCallback((event) => {
    if (event.target.value.length <= POSTS.maxChars)
      setPostMessage(event.target.value);
  }, []);

  const dismiss = useCallback(() => {
    if (!location.pathname.includes(ROUTES.profile)) setModalContext(null);
  }, [setModalContext, location]);

  return (
    <div
      className={cn(`${block} ${className}`, {
        [`${block}--transparent`]: user,
      })}
    >
      {quote && (
        <button className={`${block}__close-button`} onClick={dismiss}>
          [ esc ]
        </button>
      )}
      <div className={`${block}__inner`}>
        <textarea
          className={`${block}__input`}
          onChange={handlePostMessageChange}
          maxLength={POSTS.maxChars}
          value={postMessage}
          placeholder={quote ? "" : "Post something inspiring!"}
        ></textarea>
        <span
          className={cn(`${block}__char-counter`, {
            [`${block}__char-counter--red`]: maxCharsHighlight,
          })}
        >
          {numOfRemainChars} remaining chars
          <div
            className={cn(`${block}__char-counter-bar`, {
              [`${block}__char-counter-bar--red`]: maxCharsHighlight,
            })}
            style={{ width: charCountBarWidth }}
          ></div>
        </span>
        {quote && (
          <div className={`${block}__quote`}>
            <div className={`${block}__quote-label`}>Quotation</div>
            {quote.message || quote.originalPost?.message}
          </div>
        )}
        <footer className={`${block}__footer`}>
          <button onClick={handleCreatePost} disabled={!postMessage.trim()}>
            &gt;&nbsp;{quote ? "quote" : "post"}
            {user && ` as ${user.handle}`}
          </button>
        </footer>
      </div>
    </div>
  );
}

CreatePost.protoTypes = {
  className: PropTypes.string,
  quotedPost: PropTypes.object,
  user: PropTypes.object,
};

export default CreatePost;

import PropTypes from "prop-types";
import Lottie from "lottie-react";
import loadingAnimation from "../data/loading-animation.json";
import "./loading.scss";

const block = "loading";
function Loading({ className }) {
  return (
    <div className={`${block} ${className}`}>
      <Lottie
        className={`${block}__animation`}
        animationData={loadingAnimation}
        loop={true}
      />
    </div>
  );
}

Loading.propTypes = {
  className: PropTypes.string,
};

export default Loading;

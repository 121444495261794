const POSTS = {
  maxChars: 777,
  maxPostsPerDay: 5,
};

const API = {
  fakeDelayInSecs: 1500,
};

const STORAGE = {
  postsContext: "postsContext",
  usersContext: "usersContext",
};

const ROUTES = {
  all: "/",
  following: "/following",
  profile: "/profile",
};

const USERS = [
  {
    id: 1,
    handle: "Default User",
    joinedAt: "November 6, 2022",
    avatarColor: "#b113c0",
    following: [3],
  },
  {
    id: 2,
    handle: "Second User",
    joinedAt: "November 6, 2022",
    avatarColor: "#09926d",
    following: [3],
  },
  {
    id: 3,
    handle: "Third User",
    joinedAt: "November 6, 2022",
    avatarColor: "#bd7a00",
    following: [1, 2],
  },
];

export { POSTS, API, STORAGE, ROUTES, USERS };

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PageHome from "./pages/home";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PageHome />} />
          <Route path="/following" element={<PageHome />} />
          <Route path="/profile/:handle" element={<PageHome />} />
          <Route path="/profile/" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </React.StrictMode>
);

import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../utils/enums";
import "./user.scss";

const block = "user";
function User({ className, user, date, actionBadge, avatarOnly }) {
  const navigate = useNavigate();
  const location = useLocation();
  const userInitial = useMemo(() => {
    return user?.handle?.charAt(0) || "?";
  }, [user]);

  const handleClick = useCallback(() => {
    navigate(`${ROUTES.profile}/${user.handle}`, {
      state: { prevPath: location.pathname },
      replace: true,
    });
  }, [navigate, user, location]);

  return (
    <div
      className={`${block} ${className}`}
      role="button"
      onClick={handleClick}
    >
      <div
        className={`${block}__avatar`}
        style={{ backgroundColor: user.avatarColor || "black" }}
        title={user.handle}
      >
        {userInitial}
      </div>
      {!avatarOnly && (
        <div className={`${block}__owner`}>
          <div className={`${block}__user`}>
            {user.handle}
            {actionBadge}
          </div>
          {date && <div className={`${block}__date`}>{date}</div>}
        </div>
      )}
    </div>
  );
}

User.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  date: PropTypes.string,
  actionBadge: PropTypes.element,
  avatarOnly: PropTypes.bool,
};

export default User;

import PropTypes from "prop-types";
import { useCallback, useContext } from "react";
import { PostsContext } from "../pages/home";
import "./alert.scss";

const block = "alert";
function Alert({ className, title, message }) {
  const { setModalContext } = useContext(PostsContext);

  const dismiss = useCallback(() => {
    setModalContext(null);
  }, [setModalContext]);

  return (
    <div className={`${block} ${className}`}>
      <button className={`${block}__close-button`} onClick={dismiss}>
        [ esc ]
      </button>
      {title && <div className={`${block}__title`}>{title}</div>}
      {message && <div className={`${block}__message`}>{message}</div>}
    </div>
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Alert;

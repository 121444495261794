import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import CreatePost from "../components/create-post";
import PostList from "../components/post-list";
import Switcher from "../components/switcher";
import posts from "../data/posts.json";
import { getLocalStorage, setLocalStorage } from "../utils/storage";
import { STORAGE, ROUTES, USERS } from "../utils/enums";
import "./home.scss";
import Header from "../components/header";
import Modal from "../components/modal";
import Profile from "../components/profile";

const PostsContext = createContext();
const block = "page-home";

function PageHome() {
  const navigate = useNavigate();
  const { handle } = useParams();
  const [modalContext, setModalContext] = useState();
  const [postsContext, setPostsContext] = useState(
    () => getLocalStorage(STORAGE.postsContext) || posts
  );
  const [usersContext, setUsersContext] = useState(
    () => getLocalStorage(STORAGE.usersContext) || USERS
  );
  const user = useMemo(() => {
    if (!handle) return null;

    return usersContext.filter((user) => user.handle === handle)[0];
  }, [handle, usersContext]);

  const handleViewChange = useCallback(
    (selectedIndex) => {
      selectedIndex
        ? navigate(ROUTES.following, { replace: true })
        : navigate(ROUTES.all, { replace: true });
    },
    [navigate]
  );

  const handleResetData = useCallback(() => {
    setPostsContext(posts);
    setUsersContext(USERS);
    navigate("/", { replace: true });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [navigate]);

  useEffect(() => {
    setLocalStorage(STORAGE.postsContext, postsContext);
  }, [postsContext]);

  useEffect(() => {
    setLocalStorage(STORAGE.usersContext, usersContext);
  }, [usersContext]);

  useEffect(() => {
    if (handle && user) setModalContext(<Profile user={user} />);
    else if (handle && !user) setModalContext(<Navigate to="/" replace />);
    else setModalContext(null);
  }, [handle, user]);

  return (
    <PostsContext.Provider
      value={{
        postsContext,
        setPostsContext,
        modalContext,
        setModalContext,
        usersContext,
        setUsersContext,
      }}
    >
      <div className={block}>
        <Header />
        <div className="container">
          <CreatePost />
          <Switcher
            firstOption="All"
            secondOption="Following"
            changeHandler={handleViewChange}
          />
          <PostList />
          <button className={`${block}__reset-data`} onClick={handleResetData}>
            &gt;&nbsp;Reset data
          </button>
        </div>
        {modalContext && <Modal>{modalContext}</Modal>}
      </div>
    </PostsContext.Provider>
  );
}

export default PageHome;
export { PostsContext };
